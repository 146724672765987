import { useFieldPath } from "@/components/common/Form/hooks";
import { Form, Select } from "antd";
import { Field } from "@/components/common/Form/Field";
import { toOptions } from "@/utils/toOptions";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import { getTabSourceLabel, TabSource } from "@/utils/tab-source";
import type { SpreadsheetColumn } from "@/api/spreadsheets/types";

type Props = {
  sources?: TabSource[];
};

export const TabSourceField = ({
  sources = [TabSource.Salesforce, TabSource.OpenPlay, TabSource.OpenPlayQuery],
}: Props) => {
  const { getAbsolutePath } = useFieldPath();
  const form = Form.useFormInstance();
  const columns = Form.useWatch<SpreadsheetColumn[]>(getAbsolutePath(["columnSet", "columns"])) ?? [];
  const isDisabled = columns.length > 0;

  const handleChange = () => {
    form.setFieldValue(getAbsolutePath("query"), void 0);
    form.setFieldValue(getAbsolutePath("openplayQueryId"), void 0);
  };

  return (
    <Field
      tooltip={isDisabled ? "Tab source cannot be changed if one or more columns are added" : null}
      name="source"
      label="Source"
      validateFirst
      initialValue={TabSource.Salesforce}
    >
      <Select
        options={toOptions(sources, getTabSourceLabel)}
        disabled={isDisabled}
        suffixIcon={<Icon component={DropdownArrow} />}
        onChange={handleChange}
      />
    </Field>
  );
};
