import { Button, Form } from "antd";
import { RecipientListItem } from "./RecipientListItem/RecipientListItem";
import Icon from "@ant-design/icons";
import { Plus } from "../../Icons";
import { FieldList, FieldListItem } from "../../Form/FieldList";
import "./RecipientList.less";
import { RecipientType } from "@/api/email-alerts/types";
import type { ComponentProps } from "react";
import { useEmailMessageContext } from "@/components/common/EmailMessageForm/context";

type Props = {
  name: string | string[];
  canAddGroups?: boolean;
} & Pick<ComponentProps<typeof FieldList>, "rules">;

export const RecipientList = ({ name = [], canAddGroups = true, rules }: Props) => {
  const { sourceObject } = useEmailMessageContext();
  return (
    <FieldList name={name} rules={rules}>
      {(fields, { add, remove }, { errors }) => (
        <>
          <div className="recipient-list">
            <div className="recipient-list__actions">
              <Button
                size="small"
                onClick={() => add({ type: RecipientType.Single })}
                icon={<Icon component={Plus} />}
                className="recipient-list__add"
              >
                Add Recipient
              </Button>
              {sourceObject && canAddGroups && (
                <Button
                  size="small"
                  onClick={() => add({ type: RecipientType.Group, ifs: [], else: [] })}
                  icon={<Icon component={Plus} />}
                  className="recipient-list__add"
                >
                  Add Conditional Group
                </Button>
              )}
            </div>
            <div className="recipient-list__items">
              {fields.map((field) => (
                <FieldListItem key={field.key} name={field.name}>
                  <RecipientListItem number={field.name + 1} onRemove={() => remove(field.name)} />
                </FieldListItem>
              ))}
            </div>
          </div>
          <Form.ErrorList errors={errors} />
        </>
      )}
    </FieldList>
  );
};
