import { Col, Form, Input, Row } from "antd";
import { Field } from "@/components/common/Form/Field";
import { atLeastOne, isUniqueName } from "@/utils/validation";
import { checkPermissionSetExistence } from "@/api/permission-sets";
import { PermissionList } from "@/components/Permissions/PermissionList";

const { TextArea } = Input;

export const PermissionSetFields = () => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Field
          name="name"
          label="Name"
          validateTrigger={["onChange", "onBlur"]}
          rules={[
            { required: true, message: "Please, enter a name for the permission set" },
            isUniqueName(
              checkPermissionSetExistence,
              "A permission set with entered name already exists in the system"
            ),
          ]}
        >
          <Input placeholder="Name" />
        </Field>
      </Col>
      <Col span={12}>
        <Field name="description" label="Description">
          <TextArea placeholder="Description" rows={2} />
        </Field>
      </Col>
      <Col span={24}>
        <Form.Item label="Permissions" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
          <PermissionList rules={[atLeastOne("At least one permission should be added")]} />
        </Form.Item>
      </Col>
    </Row>
  );
};
