import { RecipientList } from "../RecipientList/RecipientList";
import { atLeastOne } from "@/utils/validation";
import { ConditionBox } from "@/components/common/ConditionBox";
import { useEmailMessageContext } from "@/components/common/EmailMessageForm/context";
import { Button, Form } from "antd";
import { FieldList, FieldListItem } from "@/components/common/Form/FieldList";
import Icon from "@ant-design/icons";
import { Plus, Remove } from "@/components/common/Icons";
import { IconButton } from "@/components/common/IconButton/IconButton";

export const RecipientGroup = () => {
  const { fieldsForConditions } = useEmailMessageContext();
  return (
    <div>
      <Form.Item label="Conditions" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
        <FieldList name="ifs" rules={[atLeastOne("At least one condition should be added")]}>
          {(fields, { add, remove }, { errors }) => (
            <div>
              <div style={{ display: "flex", flexDirection: "column", gap: 12 }}>
                {fields.map((field) => (
                  <FieldListItem key={field.key} name={field.name}>
                    <div className="list-item">
                      <div className="list-item__body">
                        <ConditionBox fields={fieldsForConditions}>
                          <RecipientList
                            name="items"
                            canAddGroups={false}
                            rules={[atLeastOne("At least one recipient should be added")]}
                          />
                        </ConditionBox>
                      </div>
                      <IconButton
                        icon={Remove}
                        onClick={() => remove(field.name)}
                        className="list-item__remove"
                      />
                    </div>
                  </FieldListItem>
                ))}
                <Button
                  size="small"
                  style={{ alignSelf: "flex-start" }}
                  icon={<Icon component={Plus} />}
                  onClick={() => add({ type: "basic", condition: null, content: [] })}
                >
                  Add Condition
                </Button>
              </div>
              {errors.length > 0 && <Form.ErrorList errors={errors} />}
            </div>
          )}
        </FieldList>
      </Form.Item>
      <Form.Item
        label="Else"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        tooltip="Content to display if none of the conditions are met"
      >
        <RecipientList name="else" canAddGroups={false} />
      </Form.Item>
    </div>
  );
};
