import { create } from "zustand";
import type { AxiosResponse } from "axios";
import { openNotification } from "@/components/common/CommonNotification";
import { parseFileName } from "@/utils";
import fileDownload from "js-file-download";

type DownloadOptions = {
  message: string;
  noContentMessage?: string;
};

type DownloadFetcher = () => Promise<AxiosResponse<Blob>>;

type DownloadStore = {
  active: string[];
  download: (key: string, fetcher: DownloadFetcher, opts: DownloadOptions) => void;
};

export const useDownloader = create<DownloadStore>((set) => ({
  active: [],
  download: async (
    key,
    fetcher,
    { message, noContentMessage = "The is no content to include into the file" }
  ) => {
    set((state) => ({ active: [...state.active, key] }));
    try {
      openNotification({
        description: message,
      });
      const response = await fetcher();
      if (response.status === 204) {
        openNotification({
          type: "warning",
          description: noContentMessage,
        });
        return;
      }
      const fileName = parseFileName(response.headers["content-disposition"]);
      fileDownload(response.data, `${fileName}`, response.headers["content-type"]);
    } catch (error) {
      console.error(error);
    } finally {
      set((state) => ({ active: state.active.filter((x) => x !== key) }));
    }
  },
}));
