import { Divider } from "antd";
import { OpenplayQueryList } from "@/components/OpenplayQueries/OpenplayQueryList";
import { ReportSalesforceQueryList } from "./ReportSalesforceQueryList";

type Props = {
  readOnly?: boolean;
};

export const ReportQueries = ({ readOnly }: Props) => {
  return (
    <div>
      <Divider style={{ marginTop: 0 }} orientation="left">
        Salesforce Queries
      </Divider>
      <ReportSalesforceQueryList disabled={readOnly} />
      <Divider orientation="left">Openplay Queries</Divider>
      <OpenplayQueryList readOnly={readOnly} />
    </div>
  );
};
