import { FieldList, FieldListItem } from "@/components/common/Form/FieldList";
import { FullWidthButton } from "@/components/common/FullWidthButton/FullWidthButton";
import { Plus } from "@/components/common/Icons";
import { EditableTabs } from "@/components/common/EditableTabs";
import type { ComponentProps } from "react";
import { useFormErrors } from "@/components/common/Form/hooks";
import { SalesforceQueryListItem } from "@/components/common/SalesforceQueryList/SalesforceQueryListItem";
import { Alert, Col, Form, Row } from "antd";
import { v4 as uuidv4 } from "uuid";
import { truncate } from "@/utils";

type TabNameProps = {
  tabIndex: number;
};

const TabName = ({ tabIndex }: TabNameProps) => {
  const name = Form.useWatch(["queries", tabIndex, "name"]) ?? null;
  return name ? truncate(name, 15) : `Query #${tabIndex + 1}`;
};

type Props = {
  name: string;
  disabled?: boolean;
  rules?: ComponentProps<typeof FieldList>["rules"];
  notClosableKeys?: number[]
};

export const SalesforceQueryList = ({ name, disabled, rules, notClosableKeys = [] }: Props) => {
  const { fieldsWithErrors } = useFormErrors();

  const invalidKeys = fieldsWithErrors
    .filter((field) => field.name[0] === "queries")
    .map((field) => field.name[1]);

  return (
    <Row gutter={[16, 16]}>
      {notClosableKeys.length > 0 && (
        <Col span={24}>
          <Alert message="Queries that are used in spreadsheet tabs, actions or parameter sources cannot be removed" />
        </Col>
      )}
      <Col span={24}>
        <FieldList name={name} rules={rules}>
          {(fields, { add, remove }, { errors }) => (
            <div>
              {fields.length > 0 ? (
                <EditableTabs
                  disabled={disabled}
                  invalidKeys={invalidKeys}
                  items={fields.map((field) => ({
                    key: field.name,
                    label: <TabName tabIndex={field.name} />,
                    content: (
                      <FieldListItem key={field.key} name={field.name}>
                        <SalesforceQueryListItem disabled={disabled} />
                      </FieldListItem>
                    ),
                    closable: !notClosableKeys.includes(field.name),
                  }))}
                  onAdd={() => add({ id: uuidv4() })}
                  onRemove={(targetKey: number) => {
                    remove(targetKey);
                  }}
                />
              ) : (
                <FullWidthButton
                  label="Add Salesforce Query"
                  icon={Plus}
                  onClick={() => add({ id: uuidv4() })}
                  disabled={disabled}
                />
              )}
              {errors.length > 0 && <Form.ErrorList errors={errors} />}
            </div>
          )}
        </FieldList>
      </Col>
    </Row>
  );
};
