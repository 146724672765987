import useSWR, { mutate } from "swr";
import useSWRMutation from "swr/mutation";
import type { CreatePermissionSet, PermissionSetFilters, UpdatePermissionSet } from "./types";
import { fetchPermissionSet, fetchPermissionSets, savePermissionSet } from "./index";

const key = "permission-sets";

export const usePermissionSets = (filters: PermissionSetFilters = {}) =>
  useSWR(filters ? [key, filters] : [key], ([_, filters]) => fetchPermissionSets(filters), {
    fallbackData: { data: [] },
  });

export const usePermissionSet = (id?: string) =>
  useSWR(id ? [key, id] : null, ([, id]) => fetchPermissionSet(id), {
    fallbackData: null,
  });

export const usePermissionSetMutation = (id: string) =>
  useSWRMutation(
    id ? [key, id] : key,
    (_, { arg: dto }: { arg: CreatePermissionSet | UpdatePermissionSet }) => savePermissionSet(dto),
    {
      onSuccess: () =>
        mutate((args) => {
          if (Array.isArray(args)) {
            return args[0] === key && typeof args[1] !== "string";
          }
          return false;
        }),
    }
  );
