import { useParams } from "react-router-dom";
import { Spinner } from "@/components/common/Spinner/Spinner";
import { PermissionSetForm } from "./PermissionSetForm";
import { usePermissionSet } from "@/api/permission-sets/hooks";

export const PermissionSetPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data: entry, isLoading } = usePermissionSet(id);

  if (isLoading) {
    return <Spinner />;
  }

  return <PermissionSetForm initialValues={entry} />;
};
