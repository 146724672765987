import { useCurrentUser } from "@/api/users/hooks";
import { useCallback, useMemo } from "react";
import { login, logout } from "@/api/auth";
import { useHistory } from "react-router-dom";
import useSWRMutation from "swr/mutation";
import type { MongoQuery, RawRule } from "@casl/ability";
import { createMongoAbility } from "@casl/ability";
import type { CurrentUser, PermissionAction, PermissionSubject } from "@/api/users/types";
import { PermissionType } from "@/api/users/types";
import { UserRole } from "@/utils/user-role";
import { createCanBoundTo } from "@casl/react";

export type AbilityTuple = [PermissionAction, PermissionSubject];

const getPermissions = (user: CurrentUser): RawRule<AbilityTuple, MongoQuery>[] => {
  if (user?.role === UserRole.Operational) {
    const permissions = [
      ...(user.permissionSets ?? []).flatMap((entry) => entry.permissionSet.permissions),
      ...(user.permissions ?? []),
    ];
    return [
      // Default permissions
      ...user.defaultPermissions,
      // Assigned permissions
      ...permissions.map((permission) => ({
        action: permission.action,
        subject: permission.subject,
        inverted: permission.type === PermissionType.Cannot,
      })),
    ];
  }
  return user?.defaultPermissions ?? [];
};

export const useAuth = () => {
  const history = useHistory();
  const { data: user, mutate, isLoading: isLoadingUser } = useCurrentUser();

  const { isMutating: isLoggingIn, trigger: triggerLoggingIn } = useSWRMutation(
    "login",
    (_, { arg: { email, password } }: { arg: { email: string; password: string } }) => login(email, password),
    { throwOnError: true }
  );

  const { isMutating: isLoggingOut, trigger: triggerLoggingOut } = useSWRMutation("logout", logout, {
    throwOnError: true,
  });

  const handleLogin = useCallback(
    async (email: string, password: string) => {
      await triggerLoggingIn({ email, password });
      await mutate();
    },
    [mutate, triggerLoggingIn]
  );

  const handleLogout = useCallback(async () => {
    await triggerLoggingOut();
    await mutate(null);
    history.push("/login");
  }, [history, mutate, triggerLoggingOut]);

  const isLoading = isLoadingUser || isLoggingIn || isLoggingOut;

  const ability = useMemo(() => createMongoAbility<AbilityTuple>(getPermissions(user)), [user]);

  const Can = useMemo(() => createCanBoundTo(ability), [ability]);

  return {
    isAuthenticated: !!user,
    user: user,
    login: handleLogin,
    logout: handleLogout,
    refresh: () => mutate(),
    ability,
    Can,
    isLoading,
  };
};
