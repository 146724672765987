import type { ColumnsType } from "antd/lib/table/interface";
import { DescriptionCell } from "@/components/common/DescriptionCell";
import type { PermissionSet } from "@/api/permission-sets/types";
import { Space } from "antd";
import { PermissionTag } from "@/components/Permissions/PermissionTag";

export const columns: ColumnsType<PermissionSet> = [
  {
    title: "Name",
    dataIndex: "name",
    sorter: true,
  },
  {
    title: "Description",
    dataIndex: "source",
    render: (_, entry) => <DescriptionCell description={entry.description} />,
  },
  {
    title: "Permissions",
    dataIndex: "permissions",
    render: (_, entry) => (
      <Space wrap>
        {entry.permissions.map((permission) => (
          <PermissionTag permission={permission} key={permission.id} />
        ))}
      </Space>
    ),
  },
];
