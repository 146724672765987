import "./PermissionListItem.less";
import { Field } from "@/components/common/Form/Field";
import { Form, Select } from "antd";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import { toOptions } from "@/utils/toOptions";
import { PermissionAction, PermissionType } from "@/api/users/types";
import { capitalize } from "@/utils";
import { PermissionSubjectPicker } from "@/components/Permissions/PermissionSubjectPicker";
import { useFieldPath } from "@/components/common/Form/hooks";
import { HiddenField } from "@/components/common/HiddenField";

export const PermissionListItem = () => {
  const { getAbsolutePath } = useFieldPath();
  const form = Form.useFormInstance();
  const action = Form.useWatch<PermissionAction>(getAbsolutePath("action"));
  const handleActionChange = () => {
    form.setFieldValue(getAbsolutePath("subject"), void 0);
  };
  return (
    <div className="permission-list-item">
      <HiddenField name="id" />
      <Field name="type" rules={[{ required: true, message: "Please, select a permission type" }]}>
        <Select
          placeholder="Select permission type"
          options={toOptions(Object.values(PermissionType), capitalize)}
          suffixIcon={<Icon component={DropdownArrow} />}
        />
      </Field>
      <Field name="action" rules={[{ required: true, message: "Please, select an action" }]}>
        <Select
          placeholder="Select action"
          options={toOptions(Object.values(PermissionAction), capitalize)}
          suffixIcon={<Icon component={DropdownArrow} />}
          onChange={handleActionChange}
        />
      </Field>
      <Field
        name="subject"
        tooltip={action ? void 0 : "Please, select an action first"}
        rules={[{ required: true, message: "Please, select a subject" }]}
      >
        <PermissionSubjectPicker action={action} />
      </Field>
    </div>
  );
};
