import { Alert, Button, Col, Divider, Form, Input, Modal, Row, Tooltip } from "antd";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { downloadSpreadsheet } from "@/api/on-demand-reports";
import { IconButton } from "@/components/common/IconButton/IconButton";
import type { DynamicFieldsByQuery, OnDemandReport } from "@/api/on-demand-reports/types";
import { useBoolean } from "ahooks";
import { Close } from "@/components/common/Icons";
import React, { Fragment, useCallback } from "react";
import type { OpenplayQuery } from "@/api/openplay-queries/types";
import { Field } from "@/components/common/Form/Field";
import { TenantPicker } from "@/components/OpenplayQueries/TenantPicker";
import { QueryParameterValueInput } from "@/components/OpenplayQueries/QueryParameterValueInput";
import { defaultValuesByType } from "@/components/OpenplayQueries/QueryParameterField";
import { useDownloader } from "@/stores/downloads";
import { uniq } from "ramda";
import { SalesforceQueryBuilder } from "@/components/common/SalesforceQuery/SalesforceQueryBuilder";
import type { Logic } from "@/api/types";

type Props = {
  report: OnDemandReport;
  iconOnly?: boolean;
};

export const SourcedParameterPlaceholder = () => (
  <Input disabled value={null} placeholder="Sourced from Salesforce query" />
);

const hasDynamicFields = (query: OpenplayQuery) =>
  !!query.defaultTenant || Object.keys(query.parameters ?? {}).length > 0;

const opts = {
  message: "The spreadsheet is being generated. Download will start automatically",
  noContentMessage: "The are no records to include into the file",
};

type FormValues = {
  openplay: DynamicFieldsByQuery;
  salesforce: Record<string, Logic>;
};

export const DownloadSpreadsheetButton = ({ report, iconOnly }: Props) => {
  const key = `on-demand-report-${report.id}`;

  const downloading = useDownloader((state) => state.active.includes(key));
  const download = useDownloader((state) => state.download);

  const [form] = Form.useForm<FormValues>();

  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

  const openplayQueriesWithParameters = report.openplayQueries.filter((query) =>
    hasDynamicFields(query.openplayQuery)
  );

  const handleOpen = () => {
    if (openplayQueriesWithParameters.length > 0 || report.queries.length > 0) {
      return showModal();
    }
    download(key, () => downloadSpreadsheet(report.id), opts);
  };

  const handleDownload = (values: FormValues) => {
    hideModal();
    form.resetFields();
    return download(
      key,
      () =>
        downloadSpreadsheet(report.id, {
          dynamicFieldsByOpenplayQuery: values.openplay,
          variationsBySalesforceQuery: values.salesforce,
        }),
      opts
    );
  };

  const handleCancel = useCallback(() => {
    hideModal();
    form.resetFields();
  }, [form, hideModal]);

  return (
    <>
      {iconOnly ? (
        <Tooltip title="Generate Report">
          <IconButton onClick={handleOpen} disabled={downloading}>
            {downloading ? <LoadingOutlined /> : <DownloadOutlined />}
          </IconButton>
        </Tooltip>
      ) : (
        <Button icon={<DownloadOutlined />} onClick={handleOpen} loading={downloading}>
          Generate Report
        </Button>
      )}
      <Modal
        open={isModalOpen}
        title="Generate Report"
        footer={
          <>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" loading={downloading} onClick={form.submit}>
              Download
            </Button>
          </>
        }
        onCancel={handleCancel}
        closeIcon={<Close />}
        width={900}
      >
        <Form form={form} onFinish={handleDownload}>
          {report.queries.length > 0 && (
            <>
              <Alert message="Add extra conditions to Salesforce queries where needed" type="info" />
              {report.queries.map((query) => (
                <Fragment key={query.id}>
                  <Divider>{query.name?.trim() || `Query #${report.queries.indexOf(query) + 1}`}</Divider>
                  <Field name={["salesforce", query.id]}>
                    <SalesforceQueryBuilder from={query.from} />
                  </Field>
                </Fragment>
              ))}
            </>
          )}
          {openplayQueriesWithParameters.length > 0 && (
            <>
              <Alert message="Please, specify dynamic parameters for OpenPlay queries" type="info" />
              {openplayQueriesWithParameters.map((query) => {
                const sourcedParameters = uniq(
                  query.parameterSources.map(({ parameter }) => parameter).filter(Boolean)
                );
                return (
                  <Fragment key={query.id}>
                    <Divider>
                      {query.name?.trim() ||
                        `Query #${report.openplayQueries.indexOf(query) + 1} (${query.openplayQuery.name})`}
                    </Divider>
                    <Row gutter={[16, 16]}>
                      {query.openplayQuery.defaultTenant && (
                        <Col span={12}>
                          <Field
                            label="Tenant"
                            name={["openplay", query.id, "tenant"]}
                            rules={[{ required: true, message: "Please, select tenant" }]}
                            wrapperCol={{ span: 24 }}
                            labelCol={{ span: 24 }}
                          >
                            <TenantPicker />
                          </Field>
                        </Col>
                      )}
                      {Object.entries(query.openplayQuery.parameters).map(([name, { type }]) => {
                        const isSourced = sourcedParameters.includes(name);
                        return (
                          <Col span={12} key={name}>
                            <Field
                              label={name.replaceAll("_", " ")}
                              name={["openplay", query.id, "parameters", name]}
                              initialValue={defaultValuesByType[type]}
                              wrapperCol={{ span: 24 }}
                              labelCol={{ span: 24 }}
                              rules={[
                                {
                                  required: !isSourced,
                                  message: "Please, provide value for the parameter",
                                },
                              ]}
                            >
                              {isSourced ? (
                                <SourcedParameterPlaceholder />
                              ) : (
                                <QueryParameterValueInput type={type} placeholder="Parameter Value" />
                              )}
                            </Field>
                          </Col>
                        );
                      })}
                    </Row>
                  </Fragment>
                );
              })}
            </>
          )}
        </Form>
      </Modal>
    </>
  );
};
