import { Form } from "antd";
import type { SpreadsheetTab } from "@/api/spreadsheets/types";
import { getEmptyArray } from "@/utils/empty";
import type { ReportAction, SalesforceQuery } from "@/api/reports/types";
import type { ReportOpenplayQuery } from "@/api/openplay-queries/types";
import { SalesforceQueryList } from "@/components/common/SalesforceQueryList";

const useNotClosableKeys = () => {
  const tabs = Form.useWatch<SpreadsheetTab[]>(["spreadsheet", "tabs"]) ?? getEmptyArray();
  const actions = Form.useWatch<{
    pre: ReportAction[];
    post: ReportAction[];
  }>(["actions"]);
  const queries = Form.useWatch<SalesforceQuery[]>(["queries"]) ?? getEmptyArray();
  const openplayQueries = Form.useWatch<ReportOpenplayQuery[]>(["openplayQueries"]) ?? getEmptyArray();

  return queries
    .filter((query) => {
      const usedInTabs = tabs.some((tab) => tab.query === query.id);
      const usedInPreActions = actions?.pre?.some((action) => action.queryId === query.id);
      const usedInPostActions = actions?.post?.some((action) => action.queryId === query.id);
      const usedInParameterSources = openplayQueries?.some((openplayQuery) =>
        openplayQuery.parameterSources?.some((param) => param.query === query.id)
      );
      return usedInTabs || usedInPreActions || usedInPostActions || usedInParameterSources;
    })
    .map((query) => queries.indexOf(query));
};

type Props = {
  disabled?: boolean;
};

export const ReportSalesforceQueryList = ({ disabled }: Props) => {
  const notClosableKeys = useNotClosableKeys();
  return <SalesforceQueryList name="queries" disabled={disabled} notClosableKeys={notClosableKeys} />;
};
