import type { FormProps } from "antd";
import { Form, Modal } from "antd";
import { useState } from "react";
import { HiddenField } from "@/components/common/HiddenField";
import { FormProvider } from "@/components/common/Form/FormProvider";
import { useFieldPath } from "@/components/common/Form/hooks";
import { usePermissionSetMutation } from "@/api/permission-sets/hooks";
import { PermissionSetFields } from "@/components/PermissionSets/Details/PermissionSetFields";

type Props = {
  onClose?: () => void;
  onFinish?: (id: string) => void;
  open?: boolean;
} & Pick<FormProps, "form">;

export const PermissionSetModal = ({ onClose, onFinish, open, form }: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const { getAbsolutePath } = useFieldPath();
  const id = Form.useWatch<string>(getAbsolutePath("id"));

  const { trigger: save, isMutating: saving } = usePermissionSetMutation(id);

  const handleFinish = async (values) => {
    try {
      const { id } = await save(values);
      onFinish(id);
      onClose();
      form.resetFields();
    } catch (e) {
      console.error(e);
    } finally {
      setSubmitting(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      title="Edit Permission Set"
      okButtonProps={{ size: "small", loading: saving || submitting }}
      cancelButtonProps={{ size: "small" }}
      okText="Save"
      width={1000}
      open={open}
      onCancel={handleCancel}
      onOk={() => {
        setSubmitting(true);
        form.submit();
      }}
    >
      <FormProvider>
        <Form form={form} onFinish={handleFinish} onFinishFailed={() => setSubmitting(false)}>
          <HiddenField name="id" />
          <PermissionSetFields />
        </Form>
      </FormProvider>
    </Modal>
  );
};
