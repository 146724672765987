import type { Content, EmailMessage, Logic, Pagination } from "@/api/types";
import type { SalesforceObject } from "@/api/salesforce-objects/types";
import type { Spreadsheet } from "@/api/spreadsheets/types";

export enum SenderType {
  Custom = "custom",
  Field = "field",
}

export interface CustomSender {
  type: SenderType.Custom;
  username?: string;
  domain: string;
}

export type ContactField = {
  type: string;
  name: string;
};

export interface FieldBasedSender {
  type: SenderType.Field;
  field: ContactField;
}

export type Sender = CustomSender | FieldBasedSender;

export enum RecipientType {
  Single = "single",
  Campaign = "campaign",
  Field = "field",
  Group = "group",
}

export type SingleContact = {
  type: RecipientType.Single;
  email: string;
  name?: string;
};

export enum CampaignFilterType {
  Condition = "condition",
  LabelGroup = "label-group",
  AdditionalLabelRecipients = "additional-label-recipients",
  RestrictedLabelRecipients = "restricted-label-recipients",
}

export type CampaignFilterByCondition = {
  type: CampaignFilterType.Condition;
  condition: Logic;
};

export enum CampaignFilterValueSource {
  Static = "static",
  Field = "field",
}

type FilterValue =
  | { source: CampaignFilterValueSource.Static; values: string[] }
  | { source: CampaignFilterValueSource.Field; field: string[] };

export type CampaignFilterByLabelGroup = {
  type:
    | CampaignFilterType.LabelGroup
    | CampaignFilterType.AdditionalLabelRecipients
    | CampaignFilterType.RestrictedLabelRecipients;
} & FilterValue;

export type CampaignFilter = CampaignFilterByCondition | CampaignFilterByLabelGroup;

export type Campaign = {
  type: RecipientType.Campaign;
  campaignId: string;
  filters: CampaignFilter[];
};

export type FieldBasedRecipient = {
  type: RecipientType.Field;
  field: ContactField;
};

export type RecipientGroup = {
  type: RecipientType.Group;
  ifs: { condition: Logic; items: Exclude<Recipient, RecipientGroup>[] }[];
  else: Exclude<Recipient, RecipientGroup>[];
};

export type Recipient = SingleContact | Campaign | FieldBasedRecipient | RecipientGroup;

export type EmailAlert = {
  id: string;
  name: string;
  description?: string;
  sourceObject: SalesforceObject;
  email: Omit<EmailMessage, "body"> & { body: Content };
  variables: string[];
  spreadsheet: Spreadsheet;
  actions?: number;
};

export type CreateEmailAlertDto = Omit<EmailAlert, "id">;
export type UpdateEmailAlertDto = Pick<EmailAlert, "id"> & Partial<CreateEmailAlertDto>;

export type EmailAlertFilters = Pagination & { sourceObject?: string };
