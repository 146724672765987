import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import { Button, Form, Popconfirm, Select } from "antd";
import { useBoolean } from "ahooks";
import { map, omit } from "ramda";
import { useAuth } from "@/utils";
import { PermissionAction, PermissionSubject } from "@/api/users/types";
import { usePermissionSets } from "@/api/permission-sets/hooks";
import { PermissionSetModal } from "./PermissionSetModal";

type Props = {
  value?: string;
  onChange?: (value?: string) => void;
};

export const PermissionSetEditor = ({ value, onChange }: Props) => {
  const {
    data: { data: entries },
    isLoading,
  } = usePermissionSets();

  const [form] = Form.useForm();

  const [isOpen, { setTrue: open, setFalse: close }] = useBoolean(false);

  const selectedPermissionSet = entries.find((entry) => entry.id === value);

  const handleClose = () => {
    form.resetFields();
    close();
  };

  const handleEdit = () => {
    form.setFieldsValue(selectedPermissionSet);
    open();
  };

  const handleDuplicate = () => {
    form.setFieldsValue(
      omit(["id"], {
        ...selectedPermissionSet,
        permissions: map(omit(["id"]), selectedPermissionSet.permissions),
      })
    );
    open();
  };

  const { ability } = useAuth();

  const canEdit = ability.can(PermissionAction.Update, PermissionSubject.PermissionSet);
  const canCreate = ability.can(PermissionAction.Create, PermissionSubject.PermissionSet);

  return (
    <div className="flex-row">
      <Select
        placeholder="Select Existing or Create New"
        allowClear
        loading={isLoading}
        disabled={isLoading}
        options={entries.map((entry) => ({ value: entry.id, label: entry.name }))}
        suffixIcon={<Icon component={DropdownArrow} />}
        value={value}
        onChange={onChange}
        style={{ width: 400 }}
      />
      {selectedPermissionSet ? (
        <>
          <Popconfirm
            title="Editing existing permission set may impact other users it is assigned to"
            okText="Edit Anyway"
            onConfirm={handleEdit}
            overlayStyle={{ maxWidth: 300 }}
            disabled={!canEdit}
          >
            <Button size="small" disabled={!canEdit}>
              Edit
            </Button>
          </Popconfirm>
          <Button size="small" disabled={!canCreate} onClick={handleDuplicate}>
            Duplicate & Edit
          </Button>
        </>
      ) : (
        <Button disabled={!canCreate} type="primary" size="small" onClick={open}>
          Create
        </Button>
      )}
      <PermissionSetModal open={isOpen} form={form} onClose={handleClose} onFinish={onChange} />
    </div>
  );
};
