import { FieldList, FieldListItem } from "@/components/common/Form/FieldList";
import { Button, Col, Form, Row } from "antd";
import Icon from "@ant-design/icons";
import { Plus } from "@/components/common/Icons";
import { v4 as uuidv4 } from "uuid";
import { SortableList, SortableListItem } from "@/components/common/SortableList";
import { PermissionSetEditor } from "@/components/Users/Details/PermissionSets/PermissionSetEditor";
import { Field } from "@/components/common/Form/Field";
import { FullWidthButton } from "@/components/common/FullWidthButton/FullWidthButton";
import { HiddenField } from "@/components/common/HiddenField";
import { uniqBy } from "ramda";
import type { UserPermissionSet } from "@/api/users/types";

type Props = {
  readOnly?: boolean;
};

const getEmptyValue = () => ({ id: uuidv4(), permissionSet: { id: void 0 } });

export const PermissionSetList = ({ readOnly }: Props) => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <FieldList
          name="permissionSets"
          rules={[
            {
              validator: (_, value: UserPermissionSet[]) => {
                if (!value) {
                  return Promise.resolve();
                }
                const uniqueItems = uniqBy((item) => item.permissionSet.id, value);
                return uniqueItems.length < value.length
                  ? Promise.reject("Please remove duplicate permission sets")
                  : Promise.resolve();
              },
            },
          ]}
        >
          {(fields, { add, remove, move }, { errors }) =>
            fields.length > 0 ? (
              <div className="field-list">
                <Button
                  className="field-list__add"
                  size="small"
                  icon={<Icon component={Plus} />}
                  onClick={() => add(getEmptyValue())}
                >
                  Add Permission Set
                </Button>
                <SortableList onMove={move}>
                  {fields.map((field) => (
                    <SortableListItem key={field.key} index={field.name} onRemove={() => remove(field.name)}>
                      <FieldListItem key={field.key} name={field.name}>
                        <HiddenField name="id" />
                        <Field name={["permissionSet", "id"]}>
                          <PermissionSetEditor />
                        </Field>
                      </FieldListItem>
                    </SortableListItem>
                  ))}
                </SortableList>
                {errors.length > 0 && <Form.ErrorList errors={errors} />}
              </div>
            ) : (
              <FullWidthButton
                label="Add Permission Set"
                icon={Plus}
                onClick={() => add(getEmptyValue())}
                disabled={readOnly}
                small
              />
            )
          }
        </FieldList>
      </Col>
    </Row>
  );
};
