import { api } from "@/api/core";
import type { Logic, PaginatedResponse } from "@/api/types";
import { stripUndefined } from "@/utils";
import type {
  CreateOnDemandReport,
  DynamicFieldsByQuery,
  OnDemandReport,
  OnDemandReportFilters,
  UpdateOnDemandReport,
} from "@/api/on-demand-reports/types";
import type { AxiosResponse } from "axios";

export const url = "/api/on-demand-reports";

export const fetchOnDemandReport = (id: string) => api.get<any, OnDemandReport>(`${url}/${id}`);

export const fetchOnDemandReports = (params: OnDemandReportFilters) =>
  api.get<any, PaginatedResponse<OnDemandReport>>(url, { params });

export const createOnDemandReport = (fields: CreateOnDemandReport) =>
  api.post<any, OnDemandReport>(url, fields);

export const updateOnDemandReport = (fields: UpdateOnDemandReport) =>
  api.put<any, OnDemandReport>(url, fields);

export const deleteOnDemandReport = (id: string) => api.delete<any, void>(`${url}/${id}`);

export const saveOnDemandReport = (fields: CreateOnDemandReport | UpdateOnDemandReport) => {
  const cleanedUpFields = stripUndefined(fields);
  return "id" in cleanedUpFields
    ? updateOnDemandReport(cleanedUpFields)
    : createOnDemandReport(cleanedUpFields);
};

export const checkOnDemandReportExistence = async (name: string, excludeId: string) =>
  api.get<any, boolean>(`${url}/exists`, { params: { name, excludeId } });

type DownloadSpreadsheetParams = {
  dynamicFieldsByOpenplayQuery?: DynamicFieldsByQuery;
  variationsBySalesforceQuery?: Record<string, Logic>;
};

export const downloadSpreadsheet = (id: string, params?: DownloadSpreadsheetParams) =>
  api.post<void, AxiosResponse<Blob>>(`${url}/${id}/download-spreadsheet`, params ?? {}, {
    responseType: "blob",
  });
