import { Field } from "@/components/common/Form/Field";
import { Remove } from "@/components/common/Icons";
import { IconButton } from "@/components/common/IconButton/IconButton";
import { OpenplayQueryEditor } from "../OpenplayQueryEditor";
import "./OpenplayQueryListItem.less";
import { HiddenField } from "@/components/common/HiddenField";
import { useOpenplayQueries } from "@/api/openplay-queries/hooks";
import { useFieldPath } from "@/components/common/Form/hooks";
import { Col, Form, Input, Row } from "antd";
import { TenantPicker } from "@/components/OpenplayQueries/TenantPicker";
import { QueryParameterValueInput } from "@/components/OpenplayQueries/QueryParameterValueInput";
import { getFieldMaxLengthRule } from "@/utils/validation";
import type { SalesforceQuery } from "@/api/reports/types";
import { ParameterSourceList } from "@/components/OpenplayQueries/OpenplayQueryList/OpenplayQueryListItem/ParameterSourceList";
import type { QueryParameterSource } from "@/api/openplay-queries/types";
import { QueryParamType } from "@/api/openplay-queries/types";
import { getEmptyArray } from "@/utils/empty";
import { uniq } from "ramda";
import { defaultValuesByType } from "@/components/OpenplayQueries/QueryParameterField";

const SourcedParameterPlaceholder = () => (
  <Input disabled value={null} placeholder="Sourced from Salesforce query" />
);

type Props = {
  fieldIndex: number;
  onRemove: () => void;
  removable?: boolean;
  hideDynamicParameters?: boolean;
  salesforceQueries?: SalesforceQuery[];
};

export const OpenplayQueryListItem = ({
  fieldIndex,
  onRemove,
  removable,
  hideDynamicParameters = false,
  salesforceQueries = [],
}: Props) => {
  const { getAbsolutePath } = useFieldPath();
  const selectedId = Form.useWatch(getAbsolutePath(["openplayQuery", "id"]));

  const {
    data: { data: queries },
    isLoading,
  } = useOpenplayQueries();

  const selectedQuery = selectedId ? queries.find((query) => query.id === selectedId) : null;

  const parameters = Object.entries(selectedQuery?.parameters ?? {});
  const arrayParameters = parameters.filter(([, { type }]) => type === QueryParamType.Array);

  const canAddParameterSources = salesforceQueries.length > 0 && arrayParameters.length > 0;

  const parameterSources =
    Form.useWatch<QueryParameterSource[]>(getAbsolutePath(["parameterSources"])) ?? getEmptyArray();

  const sourcedParameters = uniq(parameterSources.map(({ parameter }) => parameter).filter(Boolean));

  return (
    <div className="openplay-query-list-item">
      <HiddenField name="id" />
      <Row className="openplay-query-list-item__field" gutter={[16, 16]}>
        <Col span={12}>
          <Field label="Name" name="name" rules={[getFieldMaxLengthRule("Query name", 30)]}>
            <Input placeholder="Name (optional)" />
          </Field>
        </Col>
        <Col span={12}>
          <Field
            label="Query"
            name={["openplayQuery", "id"]}
            rules={[
              { required: true, message: "Please, select a query" },
              (form) => ({
                validator: (_, value) => {
                  const idsInUse = (form.getFieldValue(["openplayQueries"]) as { id: string }[])
                    .filter((_, index) => fieldIndex !== index)
                    .map((query) => query.id);
                  return idsInUse.includes(value)
                    ? Promise.reject("The query is already in use")
                    : Promise.resolve();
                },
              }),
            ]}
          >
            <OpenplayQueryEditor removable={removable} queries={queries} isLoading={isLoading} />
          </Field>
        </Col>
        {!hideDynamicParameters && selectedQuery?.defaultTenant && (
          <Col span={12}>
            <Field
              label="Tenant"
              name="tenant"
              rules={[{ required: true, message: "Please, select tenant" }]}
            >
              <TenantPicker />
            </Field>
          </Col>
        )}
        {!hideDynamicParameters &&
          parameters.map(([name, { type }]) => {
            const isSourced = sourcedParameters.includes(name);
            return (
              <Col span={12} key={name}>
                <Field
                  label={name.replaceAll("_", " ")}
                  name={["parameters", name]}
                  initialValue={defaultValuesByType[type]}
                  rules={[
                    {
                      required: !isSourced,
                      message: "Please, provide value for the parameter",
                    },
                  ]}
                >
                  {isSourced ? (
                    <SourcedParameterPlaceholder />
                  ) : (
                    <QueryParameterValueInput type={type} placeholder="Parameter Value" />
                  )}
                </Field>
              </Col>
            );
          })}
        {canAddParameterSources && (
          <Col span={24}>
            <Form.Item label="Parameter Sources" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <ParameterSourceList
                parameters={arrayParameters.map(([name]) => name)}
                salesforceQueries={salesforceQueries}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
      <IconButton icon={Remove} onClick={onRemove} disabled={!removable} />
    </div>
  );
};
