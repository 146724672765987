import { api } from "@/api/core";
import type { PaginatedResponse } from "@/api/types";
import { stripUndefined } from "@/utils";
import type { CreatePermissionSet, PermissionSet, PermissionSetFilters, UpdatePermissionSet } from "./types";

export const url = "/api/permission-sets";

export const fetchPermissionSet = (id: string) => api.get<any, PermissionSet>(`${url}/${id}`);

export const fetchPermissionSets = (params: PermissionSetFilters) =>
  api.get<any, PaginatedResponse<PermissionSet>>(url, { params });

export const createPermissionSet = (fields: CreatePermissionSet) => api.post<any, PermissionSet>(url, fields);

export const updatePermissionSet = (fields: UpdatePermissionSet) => api.put<any, PermissionSet>(url, fields);

export const deletePermissionSet = (id: string) => api.delete<any, void>(`${url}/${id}`);

export const savePermissionSet = (fields: CreatePermissionSet | UpdatePermissionSet) => {
  const cleanedUpFields = stripUndefined(fields);
  return "id" in cleanedUpFields
    ? updatePermissionSet(cleanedUpFields)
    : createPermissionSet(cleanedUpFields);
};

export const checkPermissionSetExistence = async (name: string, excludeId: string) =>
  api.get<any, boolean>(`${url}/exists`, { params: { name, excludeId } });
