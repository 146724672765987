import { Alert, Button, Col, Form, PageHeader, Popconfirm, Row, Tooltip } from "antd";
import { FormActions } from "@/components/common/FormActions";
import { HiddenField } from "@/components/common/HiddenField";
import { useFormActions } from "@/utils/useFormActions";
import { FormErrorsContext } from "@/components/common/Form/context";
import { useAuth } from "@/utils";
import { PermissionAction, PermissionSubject } from "@/api/users/types";
import { usePermissionSetMutation } from "@/api/permission-sets/hooks";
import type { CreatePermissionSet, PermissionSet, UpdatePermissionSet } from "@/api/permission-sets/types";
import { Box } from "@/components/common/Box/Box";
import { deletePermissionSet } from "@/api/permission-sets";
import { PermissionSetFields } from "./PermissionSetFields";

type Props = {
  initialValues?: Partial<PermissionSet>;
  isEditingByDefault?: boolean;
};

type FormValues = Partial<CreatePermissionSet | UpdatePermissionSet>;

export const PermissionSetForm = ({ initialValues = {}, isEditingByDefault = false }: Props) => {
  const [form] = Form.useForm<FormValues>();

  const { trigger: save } = usePermissionSetMutation(initialValues.id);

  const initialFields = initialValues;

  const {
    editing,
    submitting,
    handleFinish,
    handleFinishFailed,
    handleSubmit,
    handleCancel,
    handleEdit,
    handleValueChange,
    handleDelete,
    fieldsWithErrors,
    handleFieldsChange,
    // @ts-expect-error
  } = useFormActions<FormValues>({
    onSave: (values) => {
      // @ts-expect-error
      return save(values);
    },
    onDelete: deletePermissionSet,
    form,
    listingUrl: "/permission-sets",
    editUrl: (id) => `/permission-sets/${id}`,
    initialValues: initialFields,
    isEditingByDefault,
  });
  const title = initialValues.name ?? "New Permission Set";
  const isInUse = initialValues.users > 0;
  const isDeletable = !!initialValues.id && !editing && !isInUse;

  const { ability } = useAuth();

  const canEdit = ability.can(PermissionAction.Update, PermissionSubject.PermissionSet);
  const canDelete = ability.can(PermissionAction.Delete, PermissionSubject.PermissionSet);

  return (
    <>
      <PageHeader
        title={<h1 style={{ margin: 0 }}>{title}</h1>}
        extra={
          <>
            <FormActions
              isEditing={editing}
              onSave={handleSubmit}
              isSaving={submitting}
              onEdit={handleEdit}
              canEdit={canEdit}
              onCancel={handleCancel}
            />
            {isDeletable && (
              <Popconfirm
                title="Are you sure to delete the permission set?"
                onConfirm={handleDelete}
                okText="Yes"
                cancelText="No"
                disabled={!canDelete}
              >
                <Button disabled={!canDelete}>Delete</Button>
              </Popconfirm>
            )}
            {isInUse && !editing && (
              <Tooltip title="This permission set is currently assigned to one or more users and cannot be deleted">
                <Button disabled>Delete</Button>
              </Tooltip>
            )}
          </>
        }
      />
      <FormErrorsContext.Provider value={{ fieldsWithErrors }}>
        <Form
          form={form}
          onFinish={handleFinish}
          onFinishFailed={handleFinishFailed}
          onValuesChange={handleValueChange}
          labelAlign="left"
          onFieldsChange={handleFieldsChange}
          initialValues={initialFields}
        >
          <HiddenField name="id" />
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Box readOnly={!editing}>
                {isInUse && (
                  <Alert
                    type="warning"
                    style={{ marginBottom: 20 }}
                    message="The permission set is currently assigned to one or more users. Editing it will affect all users it's assigned to."
                  />
                )}
                <PermissionSetFields />
              </Box>
            </Col>
          </Row>
        </Form>
      </FormErrorsContext.Provider>
    </>
  );
};
