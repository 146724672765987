import { Form } from "antd";
import type { SpreadsheetTab } from "@/api/spreadsheets/types";
import { getEmptyArray } from "@/utils/empty";
import type { OnDemandReportOpenplayQuery } from "@/api/openplay-queries/types";
import type { SalesforceQuery } from "@/api/on-demand-reports/types";
import { SalesforceQueryList } from "@/components/common/SalesforceQueryList";

const useNotClosableKeys = () => {
  const tabs = Form.useWatch<SpreadsheetTab[]>(["spreadsheet", "tabs"]) ?? getEmptyArray();
  const queries = Form.useWatch<SalesforceQuery[]>(["queries"]) ?? getEmptyArray();
  const openplayQueries =
    Form.useWatch<OnDemandReportOpenplayQuery[]>(["openplayQueries"]) ?? getEmptyArray();

  return queries
    .filter((query) => {
      const usedInTabs = tabs.some((tab) => tab.query === query.id);
      const usedInParameterSources = openplayQueries?.some((openplayQuery) =>
        openplayQuery.parameterSources?.some((param) => param.query === query.id)
      );
      return usedInTabs || usedInParameterSources;
    })
    .map((query) => queries.indexOf(query));
};

type Props = {
  disabled?: boolean;
};

export const OnDemandReportSalesforceQueryList = ({ disabled }: Props) => {
  const notClosableKeys = useNotClosableKeys();
  return <SalesforceQueryList name="queries" disabled={disabled} notClosableKeys={notClosableKeys} />;
};
