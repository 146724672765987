import type { Permission } from "@/api/users/types";
import { PermissionType } from "@/api/users/types";
import { capitalize, Color } from "@/utils";
import { getSubjectLabel } from "@/components/Permissions/PermissionSubjectPicker";
import { BaseTag } from "@/components/common/BaseTag/BaseTag";

type Props = {
  permission: Permission;
};

export const PermissionTag = ({ permission }: Props) => (
  <BaseTag bodyClassName="flex-row gap-1">
    {permission.type === PermissionType.Cannot && <strong style={{ color: Color.Failed }}>CANNOT</strong>}
    <span>{capitalize(permission.action)}</span>
    <strong>{getSubjectLabel(permission.subject)}</strong>
  </BaseTag>
);
